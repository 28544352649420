import { useEffect, useState } from "react";
import { isNil } from "lodash-es";
import type { Submission } from "../types/Submission";
import useFormVersion from "./useFormVersion";
import { isFieldsComplete } from "../utils/fieldUtil";
import useFieldCollection from "./useFieldCollection";
import type { Subscription } from "rxjs";
import { noop } from "../utils/noop";

// Complete means: all the fields are available
// We must ensure all fields are written before opening a submission in both scenarios:
// - syncing submissions from another device (because fields are written async, submissions are usually available before all fields)
// - new submissions created on the current device (although it shouldn't be a problem as we use awaits when writing submission + fields)
const useSubmissionComplete = (submission: Submission): boolean => {
  const [isComplete, setIsComplete] = useState(false);
  const fieldCollection = useFieldCollection();
  const [syncSub, setSyncSub] = useState<Subscription>();
  const { formVersion } = useFormVersion(submission.formVersionId, submission.customerId, submission.formId);

  // we manually subscribe to fieldCollection instead of `useRxData()` to avoid heavy performance impact
  // otherwise, it would keep re-rendering on each field-change. Instead, we stop subscribing as soon as fields are "complete"
  useEffect(() => {
    if (isComplete || syncSub || isNil(fieldCollection) || isNil(formVersion)) {
      return;
    }

    const subscription = fieldCollection
      .find()
      .where("submissionId")
      .equals(submission.id)
      .$.subscribe((fields) => {
        if (isFieldsComplete(fields, formVersion)) {
          setIsComplete(true);
        }
      });
    setSyncSub(subscription);
  }, [formVersion, syncSub, submission.id, fieldCollection, isComplete]);

  useEffect(() => {
    if (isComplete) {
      syncSub?.unsubscribe();
    }
  }, [syncSub, isComplete]);

  // Make sure subscription will be killed on component unmounting
  useEffect(() => (syncSub ? (): void => syncSub?.unsubscribe() : noop), [syncSub]);

  return isComplete;
};

export default useSubmissionComplete;
