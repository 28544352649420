import { isEmpty, isNil } from "lodash-es";
import { deepEqual } from "fast-equals";
import type { FieldState, UniqueFieldId } from "../types/SubmissionState";
import useSubmissionStore from "./useSubmissionStore";
import type { WidgetProperties } from "../types/FormVersion";
import type { WidgetResult, WidgetResultMeta } from "../types/Field";

export type UseFieldStateResult = [
  FieldState<WidgetProperties, WidgetResult<unknown>>,
  (rawValue?: unknown, meta?: Partial<WidgetResultMeta>) => void,
];

const useFieldState = (uniqueFieldId: UniqueFieldId): UseFieldStateResult => {
  const fieldState = useSubmissionStore(
    (store) =>
      store.formState.fields.find((x) => x.uniqueFieldId === uniqueFieldId) as FieldState<
        WidgetProperties,
        WidgetResult<unknown>
      >,
  );
  const updateField = useSubmissionStore((store) => store.updateField);

  const setFieldState = (rawValue: unknown | undefined, meta: Partial<WidgetResultMeta> = {}): void => {
    if (isEmpty(meta) && deepEqual(rawValue, fieldState.value.rawValue)) {
      return; // nothing changed, prevent unnecessary persist
    }
    const humanEdited = !isNil(meta.humanEdited) ? meta.humanEdited : true;
    updateField({
      ...fieldState,
      value: { ...fieldState.value, rawValue, meta: { ...fieldState.value.meta, ...meta, humanEdited } },
    });
  };

  return [fieldState, setFieldState];
};

export default useFieldState;
