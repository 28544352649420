import type { FC } from "react";
import { useMemo } from "react";
import classNames from "classnames";
import { merge } from "lodash-es";
import { useTranslation } from "react-i18next";
import type { SubmissionFormData } from "./Form";
import Form from "./Form";
import { submissionFormDataToFields } from "../types/Field";
import { useSubmissionStoreInitializer } from "../state/useSubmissionStoreInitializer";
import type { FormVersion } from "../types/FormVersion";
import { getStaticDefaultValues } from "../utils/submissionUtil";
import useDeviceInfo from "../hooks/useDeviceInfo";
import type { SubmissionStoreOptions } from "../state/useSubmissionStore";
import { findFormVersions } from "../utils/FormEngine";
import SpinnerWithMessage from "../storybook/components/Spinner/SpinnerWithMessage";
import { buildFormState } from "../utils/fieldStateUtil";

type StaticFormProps = {
  formVersion: FormVersion;
  submissionId: string;
  data?: SubmissionFormData;
  applyDefaultValues?: boolean;
  padding?: boolean;
  readOnly?: boolean;
};

// these constants eliminate re-renders, because an inline object is seen as "new" each render
const editableOptions: SubmissionStoreOptions = { readOnly: false, persist: false, validate: false };
const readOnlyOptions: SubmissionStoreOptions = { readOnly: true, persist: false, validate: false };
const emptyData = {};

const StaticForm: FC<StaticFormProps> = ({
  formVersion,
  data = emptyData,
  applyDefaultValues = false,
  padding = false,
  readOnly = false,
  submissionId,
}) => {
  const { formId } = formVersion;
  const { id: deviceId } = useDeviceInfo();
  const formData = useMemo(
    () => (applyDefaultValues ? merge(getStaticDefaultValues(formVersion, submissionId), data) : data),
    [data, formVersion, submissionId, applyDefaultValues],
  );
  const formVersions = useMemo(() => findFormVersions(formVersion), [formVersion]);
  const formState = useMemo(
    () =>
      buildFormState(formVersion, formVersions, submissionFormDataToFields(formData), submissionId, deviceId, formData),
    [formVersion, formVersions, formData, submissionId, deviceId],
  );
  const { initialized } = useSubmissionStoreInitializer(
    submissionId,
    formId,
    formState,
    formVersion,
    readOnly ? readOnlyOptions : editableOptions,
  );
  const { t } = useTranslation();

  if (!initialized) {
    return <SpinnerWithMessage message={t("RETRIEVING_SUBMISSION_DATA")} />;
  }

  return (
    <div className={classNames("flex flex-col gap-y-6", { "px-4 py-6": padding })}>
      <Form formVersion={formVersion} submissionId={submissionId} />
    </div>
  );
};
export default StaticForm;
