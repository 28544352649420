import type { FC, MouseEventHandler } from "react";
import { useEffect, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { CameraSource } from "@capacitor/camera";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash-es";
import type { FileResult, Widget } from "../../types/Widget";
import type { UploadStatus, WidgetResult } from "../../types/Field";
import { RETRYABLE_UPLOAD_STATUSES } from "../../types/Field";
import useFileHandler from "../../hooks/useFileHandler";
import { validateUpload } from "../../utils/validationUtil";
import type { PhotoQuality } from "../../types/Photo";
import { PhotoQualitySetting } from "../../types/Photo";
import useDrawer from "../../hooks/useDrawer";
import Img from "../Img";
import InsufficientPermissionsModal from "../InsufficientPermissionsModal";
import logger from "../../utils/logger";
import { InsufficientPermissionError } from "../../hooks/useCamera";
import usePhotoHandler from "../../hooks/usePhotoHandler";
import FileFeedback from "./file/FileFeedback";
import { noop } from "../../utils/noop";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { Label } from "../../storybook/components/Label/Label";
import { Drawer } from "../../storybook/components/Drawer/Drawer";
import { Spinner } from "../../storybook/components/Spinner/Spinner";
import { IconButton } from "../../storybook/components/IconButton/IconButton";
import { DropdownMenu } from "../../storybook/components/DropdownMenu/DropdownMenu";
import { Modal } from "../../storybook/components/Modal/Modal";
import FileUploadFailedModal from "../FileUploadFailedModal";
import WidgetContainer from "../WidgetContainer";
import useStateSubmissionId from "../../state/useStateSubmissionId";
import { preloadImage } from "../../utils/fileUtil";
import FieldUploadStatus from "../FieldUploadStatus";
import useFieldUploadState from "../../hooks/useFieldUploadState";
import { useFocusId } from "../../hooks/useFocusId";

export type WidgetPhotoProperties = {
  date_name: string;
  label_text: string;
  quality: PhotoQuality;
  filename: string;
  allow_library: boolean;
  save_to_gallery: boolean;
  required: boolean;
};

const WidgetPhoto: Widget<WidgetPhotoProperties, WidgetResult<FileResult>> = ({
  fieldState,
  setFieldState,
  readOnly,
}) => {
  const { t } = useTranslation();
  const submissionId = useStateSubmissionId();
  const [showPhoto, setShowPhoto] = useDrawer(fieldState.uid);
  const [imgUrl, setImgUrl] = useState<string>();
  const [showDeletePhotoModal, setDeletePhotoModal] = useState(false);
  const [showFailedUploadModal, setFailedUploadModal] = useState(false);
  const [showPermissionsModal, setPermissionsModal] = useState(false);
  const { getFileUrl } = useFileHandler();
  const { addPhoto } = usePhotoHandler();
  const { isUploading, uploadPercentage } = useFieldUploadState(fieldState);
  const onCloseFocusId = useFocusId(fieldState);

  useEffect(() => {
    const loadImgUrl = async (): Promise<void> => {
      if (!fieldState.value.rawValue?.id) {
        setImgUrl(undefined);
        return;
      }
      const fileUrl = await getFileUrl(fieldState.value.rawValue, submissionId);
      const src = await preloadImage(fileUrl); // avoids flickering while loading remote image
      setImgUrl(src);
    };
    loadImgUrl().catch((e) => logger.error("Could not load image", e));
  }, [fieldState.value.rawValue?.remoteId]); // eslint-disable-line react-hooks/exhaustive-deps

  const persistWithUploadStatus = (fileResult?: FileResult, uploadStatus?: UploadStatus): void => {
    setFieldState(fileResult, { uploadStatus });
  };

  const retryUpload = (): void => {
    setFieldState(fieldState.value.rawValue, { uploadStatus: "uploading" });
  };

  const handleAddPhoto = async (source: CameraSource): Promise<void> => {
    try {
      const { webPath, fileResult, uploadStatus } = await addPhoto({
        source,
        options: {
          ...PhotoQualitySetting[fieldState.properties.quality],
          saveToGallery: fieldState.properties.save_to_gallery,
        },
      });
      setImgUrl(webPath);
      persistWithUploadStatus(fileResult, uploadStatus);
    } catch (error: any) {
      if (error instanceof InsufficientPermissionError) {
        setPermissionsModal(true);
        return;
      }
      if (error.message !== "USER_CANCELLED" && error.message !== "User cancelled photos app") {
        logger.error("Failed to add photo", error);
      }
    }
  };

  const getAddPhotoButton = (onClick: MouseEventHandler<HTMLButtonElement> = noop): JSX.Element => (
    <IconAndTextButton
      id={onCloseFocusId}
      disabled={readOnly}
      label={!readOnly ? t("ADD_PHOTO") : t("NO_PHOTO_ADDED")}
      icon="CameraIcon"
      onClick={onClick}
      block
    />
  );

  const isInvalidExtensionStatus = fieldState.value.meta.uploadStatus === "invalid_extension";

  return (
    <WidgetContainer fieldState={fieldState} name="PHOTO_FIELD">
      <Label
        id={fieldState.uniqueFieldId}
        label={fieldState.properties.label_text}
        required={fieldState.properties.required}
        showClearBtn={!isNil(fieldState.value.rawValue) && !readOnly && !isInvalidExtensionStatus && !isUploading}
        clearLabel={t("CLEAR")}
        onClear={() => setDeletePhotoModal(true)}
      />
      <ViewPhotoDrawer
        open={showPhoto}
        title={fieldState.properties.label_text}
        onClose={() => setShowPhoto(false)}
        imgUrl={imgUrl}
      />
      {imgUrl ? (
        <PhotoPreview
          imgUrl={imgUrl}
          onClick={() => setShowPhoto(true)}
          isUploading={isUploading}
          uploadStatus={fieldState.value.meta.uploadStatus}
          onUploadRetry={() => setFailedUploadModal(true)}
          uploadPercentage={uploadPercentage}
          readonly={readOnly}
        />
      ) : fieldState.properties.allow_library ? (
        <DropdownMenu
          className="w-full"
          menuButton={() => getAddPhotoButton()}
          disabled={readOnly}
          items={[
            {
              label: t("CAPTURE_PHOTO"),
              onClick: () => handleAddPhoto(CameraSource.Camera),
            },
            {
              label: t("BROWSE_PHOTOS"),
              onClick: () => handleAddPhoto(CameraSource.Photos),
            },
          ]}
        />
      ) : (
        getAddPhotoButton(() => handleAddPhoto(CameraSource.Camera))
      )}
      <FileFeedback fieldState={fieldState} />
      <DeletePhotoModal
        onCloseFocusId={onCloseFocusId}
        open={showDeletePhotoModal}
        onClose={() => setDeletePhotoModal(false)}
        onDelete={async () => {
          persistWithUploadStatus(undefined, undefined);
          setImgUrl(undefined);
          setShowPhoto(false);
          setDeletePhotoModal(false);
        }}
      />

      {showPermissionsModal && (
        <InsufficientPermissionsModal show={showPermissionsModal} onClose={() => setPermissionsModal(false)} />
      )}

      {showFailedUploadModal && (
        <FileUploadFailedModal
          show={showFailedUploadModal}
          onClose={() => setFailedUploadModal(false)}
          onRetry={retryUpload}
        />
      )}
    </WidgetContainer>
  );
};

WidgetPhoto.defaultValue = (_properties, defaultMeta: any): WidgetResult<FileResult> => ({
  type: "file",
  meta: {
    widget: "photo",
    ...defaultMeta,
  },
});

WidgetPhoto.validate = (val, properties, t, meta): string | undefined => {
  if (properties.required && !val) {
    return t("VALIDATION_REQUIRED");
  }
  return validateUpload(val, meta.uploadStatus, t);
};

type DeleteModalProps = {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  onCloseFocusId?: string;
};

const DeletePhotoModal: FC<DeleteModalProps> = ({ open, onClose, onDelete, onCloseFocusId }) => {
  const { t } = useTranslation();
  return (
    <Modal
      onCloseFocusId={onCloseFocusId}
      title={t("DELETE_PHOTO")}
      content={{
        kind: "message",
        message: t("DELETE_PHOTO_CONFIRM"),
      }}
      open={open}
      onClose={onClose}
      buttons={[
        {
          label: t("CANCEL"),
          onClick: onClose,
        },
        {
          label: t("DELETE"),
          variant: "destructive",
          onClick: onDelete,
        },
      ]}
    />
  );
};

type ViewPhotoDrawerProps = {
  imgUrl: string | undefined;
  onClose: () => void;
  title: string;
  open: boolean;
};

const ViewPhotoDrawer: FC<ViewPhotoDrawerProps> = ({ imgUrl, onClose, title, open }) => {
  const { t } = useTranslation();
  return (
    <Drawer
      open={open}
      header={{
        kind: "simple",
        title,
        button: { kind: "icon", icon: "XIcon", onClick: onClose },
      }}
      className="bg-gray-100"
      onClose={onClose}
      contentPadding={false}
    >
      <TransformWrapper centerOnInit>
        <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
          <Img
            src={imgUrl}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              width: "1000px",
              height: "1000px",
              objectFit: "scale-down",
            }}
            alt={t("PHOTO")}
          />
        </TransformComponent>
      </TransformWrapper>
    </Drawer>
  );
};

type PhotoPreviewProps = {
  imgUrl: string;
  onClick: () => void;
  onUploadRetry: () => void;
  uploadPercentage: number;
  uploadStatus: UploadStatus | undefined;
  isUploading: boolean;
  readonly: boolean;
};

const PhotoPreview: FC<PhotoPreviewProps> = ({
  imgUrl,
  onClick,
  onUploadRetry,
  uploadPercentage,
  uploadStatus,
  isUploading,
  readonly,
}) => {
  const { t } = useTranslation();
  return (
    <div className="relative">
      <button
        aria-label={t("VIEW_ENTIRE_IMAGE")}
        className="relative z-10 block w-full rounded-lg border-2 bg-white outline-none focus-visible:ring"
        onClick={onClick}
      >
        {isUploading && (
          <div className="relative flex h-40 items-center justify-center">
            <Spinner className="size-8" />
          </div>
        )}

        {!isUploading && <Img src={imgUrl} alt={t("PHOTO")} className="relative z-10 mx-auto h-40 object-cover" />}
      </button>

      <div className="absolute right-4 top-4 z-10 flex space-x-5">
        {uploadStatus === "uploaded" && (
          <IconButton aria-label={t("VIEW_ENTIRE_IMAGE")} icon="EyeIcon" onClick={onClick} />
        )}

        {uploadStatus && RETRYABLE_UPLOAD_STATUSES.includes(uploadStatus) && !readonly && (
          <IconButton
            aria-label={t("UPLOAD_FAILED")}
            icon="ExclamationCircleIcon"
            iconColor="destructive"
            onClick={(e) => {
              e.stopPropagation();
              onUploadRetry();
            }}
          />
        )}
      </div>

      <FieldUploadStatus percentage={uploadPercentage} status={uploadStatus} />
    </div>
  );
};

export default WidgetPhoto;
