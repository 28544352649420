import type { RxJsonSchema } from "rxdb";
import type { DataSourceMeta } from "../types/Datasource";

export const dataSourceSchema: RxJsonSchema<DataSourceMeta> = {
  title: "DataSource Meta schema",
  description: "Single entry of a dataSource",
  version: 1,
  primaryKey: "id",
  type: "object",
  keyCompression: true,
  properties: {
    id: { type: "string", maxLength: 36 },
    customerId: { type: "number", minimum: 1, maximum: 999_999_999, multipleOf: 1 },
    updatedAt: { type: "string", maxLength: 36 },
    version: { type: "string", maxLength: 36 },
  },
  required: ["id", "customerId", "version"],
  indexes: ["customerId"],
};
