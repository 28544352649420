import { isNil, round } from "lodash-es";
import { useTranslation } from "react-i18next";
import type { CalculationOperator, CurrencyResult, Widget } from "../../types/Widget";
import type { Currency } from "../../types/Currency";
import type { FormFieldId, WidgetResult } from "../../types/Field";
import { formatCurrency } from "../../utils/formatter";
import { TextInput } from "../../storybook/components/TextInput/TextInput";
import { toIsoCurrency } from "../../utils/currencyUtil";
import WidgetContainer from "../WidgetContainer";

export type WidgetCalculationProperties = {
  label_text: string;
  initial_value?: number;
  no_value_on_missing_term: boolean;
  operator: CalculationOperator;
  precision?: number;
  terms?: FormFieldId[];
  skip_hidden_terms?: boolean;
} & ({ isPrice: true; currency?: Currency } | { isPrice: false });

const WidgetCalculation: Widget<WidgetCalculationProperties, WidgetResult<CurrencyResult>> = ({ fieldState }) => {
  const { t } = useTranslation();
  const { rawValue } = fieldState.value;
  const precision = !isNil(fieldState.properties.precision) ? fieldState.properties.precision : 2;

  const getValueAsText = (): string => {
    if (isNil(rawValue?.value) || Number.isNaN(rawValue.value)) {
      return "";
    }
    return fieldState.properties.isPrice
      ? formatCurrency(rawValue.value, navigator.language, {
          currency: toIsoCurrency(fieldState.properties.currency),
          currencyDisplay: "narrowSymbol",
          maximumFractionDigits: precision,
        })
      : round(rawValue.value, precision).toString();
  };

  return (
    <WidgetContainer fieldState={fieldState} name="CALCULATION_FIELD">
      <TextInput
        type="text"
        externalInput
        disabled
        name={fieldState.uid}
        value={getValueAsText()}
        label={fieldState.properties.label_text}
        placeholder={t("CALCULATE_MISSING_VALUES")}
      />
    </WidgetContainer>
  );
};

WidgetCalculation.defaultValue = (_properties, defaultMeta: any): WidgetResult<CurrencyResult> => ({
  type: "currency",
  meta: {
    widget: "calculation",
    ...defaultMeta,
  },
});

WidgetCalculation.validate = (): undefined => undefined;

export default WidgetCalculation;
