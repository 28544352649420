import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTimeout } from "usehooks-ts";
import { Text } from "../storybook/components/Text/Text";
import { seconds } from "../utils/timeUtil";
import InitializationErrorPage from "./errorpages/InitializationErrorPage";
import type { LoadingCondition } from "./errorpages/LoadingContent";
import LoadingContent from "./errorpages/LoadingContent";
import CommonErrorPage from "./errorpages/CommonErrorPage";

type LoadingPageProps = {
  color?: string;
  conditions: LoadingCondition[];
  skipNavigation?: boolean;
};

const INITIAL_DELAY = 250;
const EXCESSIVE_LOADING_DELAY = seconds(5);
const ERRORED_LOADING_DELAY = seconds(10);
type Status = "quick_loading" | "loading" | "excessive_loading" | "error";
const LoadingPage: FC<LoadingPageProps> = ({ color = "bg-branding", conditions, skipNavigation }) => {
  const [status, setStatus] = useState<Status>("quick_loading");
  useTimeout(() => setStatus("loading"), INITIAL_DELAY);
  useTimeout(() => setStatus("excessive_loading"), EXCESSIVE_LOADING_DELAY);
  useTimeout(() => setStatus("error"), ERRORED_LOADING_DELAY);

  const { t } = useTranslation();

  if (status === "error") {
    return skipNavigation ? (
      <InitializationErrorPage errorContent={<LoadingContent conditions={conditions} />} />
    ) : (
      <CommonErrorPage errorContent={<LoadingContent conditions={conditions} />} />
    );
  }
  return (
    <main className={`absolute inset-0 z-30 flex justify-center ${color}`}>
      {(status === "loading" || status === "excessive_loading") && (
        <div className="flex flex-col justify-center gap-y-7 text-center">
          <Text color="white" className="w-52">
            {status === "loading" ? t("LOADING_SCREEN_MESSAGE") : t("LOADING_SCREEN_MESSAGE_EXCESSIVE")}
          </Text>

          <div className="w-full overflow-hidden rounded-full bg-gray-900/30">
            <div className="h-1 w-10 animate-loading rounded-full bg-white" />
          </div>
        </div>
      )}
    </main>
  );
};

export default LoadingPage;
