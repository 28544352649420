import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { useMoreAppClient } from "../context/MoreAppContext";
import type { DataSource } from "../types/Datasource";
import { fetchDataSource, onDatasourceError, onDatasourceSuccess } from "../utils/datasourceUtil";
import { hours, minutes } from "../utils/timeUtil";
import useDataSourceCollection from "./useDataSourceCollection";
import { useEffect } from "react";

const useDatasource = (id: string, disableRetry = false): UseQueryResult<DataSource, Error> => {
  const { customerId, username } = useAuth();
  const client = useMoreAppClient();
  const datasourceCollection = useDataSourceCollection();

  const query = useQuery<DataSource, Error, DataSource>({
    queryKey: ["datasources", customerId, id],
    queryFn: () => fetchDataSource(username!, customerId!, id, client!, datasourceCollection!),
    enabled: !!customerId && !!id && !!datasourceCollection && !!username,
    gcTime: minutes(15),
    staleTime: hours(1),
    ...(disableRetry && { retry: false }),
    networkMode: "always",
  });

  // These useEffects are bad practice, however we don't want to change the current behavior
  useEffect(() => {
    if (query.isSuccess) {
      onDatasourceSuccess(username!, customerId!, query.data!, id, datasourceCollection!);
    }
  }, [query.isSuccess]);

  useEffect(() => {
    if (query.isError) {
      onDatasourceError(username!, customerId!, query.error!, id);
    }
  }, [query.isError]);

  return query;
};

export default useDatasource;
