import type { FormState, SubmissionStoreOptions } from "./useSubmissionStore";
import useSubmissionStore from "./useSubmissionStore";
import type { FormVersion } from "../types/FormVersion";

const useActionLoadForm = (): ((
  formVersion: FormVersion,
  submissionId: string,
  deviceId: string,
  formId: string,
  userId: string | undefined,
  username: string | undefined,
  options: SubmissionStoreOptions,
  initialFormState: FormState,
) => void) => useSubmissionStore((store) => store.loadForm);

export default useActionLoadForm;
