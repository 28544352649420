import { GraphQLClient } from "graphql-request";
import type { GraphQLFieldAggregateResponse, GraphQLSubmissionAggregateResponse } from "./GraphQLReplicator";
import { SYNC_URLS } from "./GraphQLReplicator";
import { QUERIES } from "../../utils/databaseUtil";
import type { GraphQLClientRequestHeaders } from "graphql-request/build/cjs/types";
import { seconds } from "../../utils/timeUtil";
import logger from "../../utils/logger";

export const totalSubmissionRequest = async (
  authHeader: GraphQLClientRequestHeaders,
  seq: number,
  timeout = seconds(10),
): Promise<GraphQLSubmissionAggregateResponse> => {
  const abortController = new AbortController();
  const signal = abortController.signal;

  setTimeout(() => {
    abortController.abort();
  }, timeout);

  const client = new GraphQLClient(SYNC_URLS.http, {
    headers: authHeader,
    signal,
  });

  try {
    return await client.request<GraphQLSubmissionAggregateResponse>(QUERIES.COUNT.submissions(seq === 0), {
      seq: seq ?? 0,
    });
  } catch (error) {
    logger.warn("Error fetching total submissions", error);
    return { app_submissions_aggregate: { aggregate: { count: 0 } } };
  }
};

export const totalFieldsRequest = async (
  authHeader: GraphQLClientRequestHeaders,
  seq: number,
  timeout = seconds(10),
): Promise<GraphQLFieldAggregateResponse> => {
  const abortController = new AbortController();
  const signal = abortController.signal;

  setTimeout(() => {
    abortController.abort();
  }, timeout);

  const client = new GraphQLClient(SYNC_URLS.http, {
    headers: authHeader,
    signal,
  });

  try {
    return await client.request<GraphQLFieldAggregateResponse>(QUERIES.COUNT.fields(seq === 0), {
      seq: seq ?? 0,
    });
  } catch (error) {
    logger.warn("Error fetching total fields", error);
    return { app_submission_fields_aggregate: { aggregate: { count: 0 } } };
  }
};
