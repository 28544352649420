import type { Field, RememberedField, WidgetResult } from "../types/Field";
import type { AbstractForm, FormField, FormVersion, WidgetProperties } from "../types/FormVersion";
import type { SubmissionFormData } from "../components/Form";
import type { FormState } from "../state/useSubmissionStore";
import type { FieldState } from "../types/SubmissionState";
import { getWidgetProperties } from "./formUtil";
import { isNil } from "lodash-es";
import logger from "./logger";
import { notEmpty } from "./arrayUtil";

export const buildFormState = (
  formVersion: FormVersion,
  formVersions: AbstractForm[],
  fields: Field[],
  submissionId: string,
  deviceId: string,
  formData: SubmissionFormData,
  rememberedFields: RememberedField[] = [],
): FormState => {
  const formFieldMap = formVersions
    .flatMap((formVersion) => formVersion.fields)
    .reduce((acc, field) => acc.set(field.uid, field), new Map<string, FormField<any>>());

  return {
    rememberedFields,
    description: "",
    fields: fields
      .sort((a, b) => a.order - b.order)
      .map((field): FieldState<WidgetProperties, WidgetResult<unknown>> | undefined => {
        const formField = formFieldMap.get(field.formFieldId);
        if (isNil(formField)) {
          logger.error("Could not find field in FormVersion", null, {
            extra: { fieldId: field.formFieldId, submissionId, formVersionId: formVersion.id },
          });
          return undefined;
        }
        const uniqueFieldId = field.id;
        const properties = getWidgetProperties(formField.widget, formField.properties);
        const widgetResult = formData[uniqueFieldId];
        if (isNil(widgetResult)) {
          logger.error("Could not find field result (value) for uniqueFieldId", null, {
            extra: { uniqueFieldId, submissionId, formVersionId: formVersion.id },
          });
          return undefined;
        }
        return {
          uid: formField.uid,
          uniqueFieldId,
          deviceId,
          value: widgetResult,
          visible: !field.hidden,
          widget: formField.widget,
          error: field.error,
          deleted: false,
          properties,
        };
      })
      .filter(notEmpty),
  };
};
