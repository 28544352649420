import { isNil } from "lodash-es";
import type { Currency } from "../types/Currency";
import { currencyMapping, ISOCurrency } from "../types/Currency";

export const toIsoCurrency = (currency?: string): string => {
  if (isNil(currency)) {
    return "EUR"; // fallback
  }

  if (currency in ISOCurrency) {
    return currency;
  }

  // Fallback
  return currencyMapping[currency as Currency];
};

export const toIsoCurrencySymbol = (currency?: string): string => ISOCurrency[toIsoCurrency(currency)];
