import type { FC, MouseEventHandler } from "react";
import classNames from "classnames";
import type { MenuItem } from "../DropdownMenu/DropdownMenu";
import { DropdownMenu } from "../DropdownMenu/DropdownMenu";
import type { ButtonVariant } from "../Button/Button";
import type { FreestyleComponentProps } from "../FreestyleComponent";
import FreestyleComponent from "../FreestyleComponent";
import { TextButton } from "../TextButton/TextButton";

export type DrawerFooterProps = DefaultDrawerFooterProps | FreestyleComponentProps;

type DrawerFooterButtonProps = {
  className?: string;
  label: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  disabled?: boolean;
  dropDownMenu?: DrawerFooterButtonDropdownMenuProps;
};

type DefaultDrawerFooterProps = {
  kind: "default";
  className?: string;
  primaryButton: DrawerFooterButtonProps;
  secondaryButton?: DrawerFooterButtonProps;
};

type DrawerFooterButtonDropdownMenuProps = {
  className?: string;
  items: MenuItem[];
};

const DrawerFooterButton: FC<DrawerFooterButtonProps & { variant: ButtonVariant; block?: boolean }> = (props) => {
  const button = (
    <TextButton
      className={props.className}
      label={props.label}
      onClick={props.onClick}
      loading={props.loading}
      disabled={props.disabled}
      variant={props.variant}
      block={props.block}
    />
  );

  return props.dropDownMenu ? (
    <DropdownMenu
      className={classNames("w-full", props.dropDownMenu.className)}
      menuButton={() => button}
      items={props.dropDownMenu.items}
    />
  ) : (
    button
  );
};

const DefaultDrawerFooter: FC<DefaultDrawerFooterProps> = ({ primaryButton, secondaryButton, className }) => (
  <div className={classNames("flex gap-x-2 p-6", className)}>
    {secondaryButton && <DrawerFooterButton {...secondaryButton} variant="default" className="basis-1/3" />}
    <DrawerFooterButton {...primaryButton} variant="primary" className="grow basis-2/3" />
  </div>
);

const DrawerFooter: FC<DrawerFooterProps> = (props) => {
  switch (props.kind) {
    case "default":
      return <DefaultDrawerFooter {...props} />;
    case "freestyle":
      return <FreestyleComponent {...props} />;
  }
};

export default DrawerFooter;
