import type { FC, PropsWithChildren } from "react";
import { createContext, useEffect, useMemo, useRef, useState } from "react";
import type { RxDatabase } from "rxdb";
import { Provider } from "rxdb-hooks";
import { useInterval } from "usehooks-ts";
import { Device } from "@capacitor/device";
import { App } from "@capacitor/app";
import { isNil } from "lodash-es";
import { closeAllConnections, createDatabase, type DBCollections, getDatabaseName } from "../utils/databaseUtil";
import useAuth from "../hooks/useAuth";
import logger from "../utils/logger";
import { GraphQLReplicator } from "../database/replication/GraphQLReplicator";
import useOnlineStatus from "../hooks/useOnlineStatus";
import { seconds } from "../utils/timeUtil";
import type { TranslationKeys } from "../i18n";
import type { PullProgress } from "../database/replication/PullProgress";

const databaseStatus = {
  Init: "init",
  Initializing: "initializing",
  Ready: "ready",
} as const;

type DatabaseStatus = (typeof databaseStatus)[keyof typeof databaseStatus];
export type CorruptionState = {
  actionLabel?: TranslationKeys;
  messageLabel: TranslationKeys;
  action?: () => void;
};

export const DatabaseProvider: FC<PropsWithChildren<object>> = ({ children }) => {
  const { authorization, refreshAccessToken } = useAuth();
  const [isAnonymousDatabase, setAnonymousDatabase] = useState<boolean | undefined>(undefined);
  const [database, setDatabase] = useState<RxDatabase<DBCollections>>();
  const [isSyncActive, setSyncActive] = useState<boolean>(false);
  const replicator = useRef<GraphQLReplicator>();
  const dbStatus = useRef<DatabaseStatus>(databaseStatus.Init);
  const [isCorrupt, setCorrupt] = useState<CorruptionState | undefined>(undefined);
  const [isInitiallySynced, setInitiallySynced] = useState<boolean>(true);
  const [pullProgress, setPullProgress] = useState<PullProgress>();
  const { isOnline } = useOnlineStatus();
  const shouldSync = useMemo(
    () => !!authorization?.accessToken && authorization.type === "oauth",
    [authorization?.type], // eslint-disable-line react-hooks/exhaustive-deps
  );

  // Use as escape hatch, save replication when we're in an unknown scenario where it should sync
  useInterval(
    () => {
      if (shouldSync && database && !isSyncActive && isOnline && replicator.current?.status === "stopped") {
        restartReplication()
          .then(() => {
            setInitiallySynced(true);
          })
          .catch((e) => {
            logger.error("Couldn't restart replication", e);
          });
      }
    },
    shouldSync ? seconds(10) : null,
  );

  const initialize = async (userId: string): Promise<void> => {
    try {
      await setupDatabase(userId);
      setCorrupt(undefined);
    } catch (e: any) {
      logger.error("Failed to initialize database", e);
      if (e?.code === "DB6" || e.code === "initialization_schema_failed") {
        // Unrecoverable error
        setCorrupt({ messageLabel: "DATABASE_CORRUPT_MESSAGE" });
      }
      if (e?.code === "initialization_failed") {
        // Show exit app
        setCorrupt({
          messageLabel: "DATABASE_INITIALIZATION_FAILED_MESSAGE",
          actionLabel: "DATABASE_INITIALIZATION_FAILED_BUTTON",
          action: async () => {
            await App.exitApp();
          },
        });
      }
    }
  };

  // Initialize new database on user change (i.e. logout/login to different user)
  useEffect(() => {
    if (isAnonymousDatabase === undefined) {
      // Page not loaded yet
      return;
    }
    if (isAnonymousDatabase && authorization.type !== "token") {
      // Authentication isn't yet to anonymous yet, skip setup
      return;
    }

    if (isNil(authorization.userId)) {
      return;
    }
    initialize(authorization.userId);
  }, [authorization?.userId, isAnonymousDatabase]); // eslint-disable-line react-hooks/exhaustive-deps

  // Start replication when applicable
  useEffect(() => {
    if (database && isOnline && !replicator.current && shouldSync) {
      restartReplication()
        .then(() => {
          setInitiallySynced(true);
        })
        .catch((e) => {
          logger.error("Couldn't start replication", e);
        });
    }
    if (!database && replicator.current) {
      stopReplication()
        .then(() => {
          setInitiallySynced(true);
        })
        .catch((e) => {
          logger.error("Couldn't stop replication", e);
        });
    }
  }, [database, isOnline]); // eslint-disable-line react-hooks/exhaustive-deps

  // Track broken websocket
  useEffect(() => {
    if (replicator.current?.subscriptionClient) {
      replicator.current?.subscriptionClient?.on("connected", () => setSyncActive(true));
      replicator.current?.subscriptionClient?.on("closed", () => setSyncActive(false));
    }
  }, [replicator.current?.subscriptionClient]);

  const setupDatabase = async (userId: string): Promise<RxDatabase<DBCollections> | undefined> => {
    if (dbStatus.current === databaseStatus.Init) {
      await closeAllConnections();
    }
    dbStatus.current = databaseStatus.Initializing;
    if (!authorization?.userId) {
      return database;
    }
    if (database) {
      await destroy();
    }
    const newDb = await createDatabase(getDatabaseName(userId));
    setDatabase(newDb);
    dbStatus.current = databaseStatus.Ready;
    return newDb;
  };

  const restartReplication = async (): Promise<void> => {
    if (database && dbStatus.current === databaseStatus.Ready) {
      if (isActiveState()) {
        await replicator.current?.stop();
      }
      const { identifier: deviceId } = await Device.getId();
      replicator.current = new GraphQLReplicator(
        database,
        refreshAccessToken,
        setInitiallySynced,
        deviceId,
        setPullProgress,
      );
      await replicator.current?.start();
    }
  };

  const stopReplication = async (): Promise<void> => {
    await replicator.current?.stop();
    replicator.current = undefined;
  };

  const destroy = async (): Promise<void> => {
    await stopReplication();
    await database?.destroy();
    setDatabase(undefined);
  };

  const isActiveState = (): boolean =>
    replicator.current?.status === "starting" ||
    replicator.current?.status === "active" ||
    replicator.current?.status === "init";

  return (
    <DatabaseContext.Provider
      value={{
        database,
        replicator: replicator.current,
        isCorrupt,
        isInitiallySynced,
        isSyncActive,
        destroy,
        pullProgress,
        setAnonymousDatabase,
      }}
    >
      <Provider db={database}>{children}</Provider>
    </DatabaseContext.Provider>
  );
};

type DatabaseState = {
  database?: RxDatabase<DBCollections>;
  replicator?: GraphQLReplicator;
  isCorrupt?: CorruptionState;
  isInitiallySynced: boolean;
  isSyncActive: boolean;
  destroy: () => Promise<void>;
  pullProgress?: PullProgress;
  setAnonymousDatabase: (value: boolean) => void;
};

export const DatabaseContext = createContext<DatabaseState>({} as DatabaseState);
