import { isEmpty } from "lodash-es";
import { v5 as uuidv5 } from "uuid";
import { Buffer } from "buffer";
import type { Duration } from "luxon";
import type { ApiLocation, LocationResult } from "../types/Widget";
import { delimiter } from "./formatter";
import { IMAGE_OPT_URL } from "../constants";
import { isValidEmail } from "./emailUtil";

const URL_REGEX = /^https?:\/\/.+/; // If it starts with 'http(s)://' then we treat is as a URL
const PHONE_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im; // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
const MOREAPP_NAMESPACE = "ef936c36-a8dc-4ed8-9246-ae3f02372813";
const PRECISION_DEFAULT = 2;

export const normalize = (value: string): string =>
  value
    .toLowerCase() // FIXME, we should use toLocaleLowerCase(language) instead, using i18n
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

export const isUrl = (value: string): boolean => (!value ? false : URL_REGEX.test(value));

export const isEmail = (value: string): boolean => (!value ? false : isValidEmail(value));

/**
 * This validation shouldn't be trusted for input validation. It might not detect every format or give false positives.
 * It's only meant to ideally show tel: anchors when we think it's a phone number.
 */
export const isPhoneNumber = (value: string): boolean => (!value ? false : PHONE_REGEX.test(value.replace(/\s+/g, "")));

export const isPrice = (price: string, precision = 2): boolean =>
  new RegExp(`^-?(\\d+[.,]?\\d{0,${precision}})?$`).test(price);

export const durationToString = (
  duration: Duration,
  options: { realTime?: boolean; forceHours?: boolean; includeMillis?: boolean } = {},
): string => {
  if (options.forceHours || duration.as("hours") >= 1) {
    return duration.toFormat(`hh:mm:ss${options.includeMillis ? ".SSS" : ""}`);
  }
  if (!options.realTime || options.includeMillis) {
    return duration.toFormat("mm:ss.SSS");
  }
  return duration.toFormat("mm:ss");
};

export const locationToHumanReadableString = (locationResult?: LocationResult): string => {
  if (locationResult?.location) {
    const formatted = formatLocation(locationResult.location);
    if (!isEmpty(formatted.trim())) {
      return formatted;
    }
  }
  if (locationResult?.coordinates) {
    return `${locationResult.coordinates?.latitude}, ${locationResult.coordinates?.longitude}`;
  }
  return locationResult?.formattedValue ?? "";
};

const formatLocation = (location: ApiLocation): string =>
  [
    location.descriptiveName || "",
    `${location.road || ""} ${location.houseNumber || ""}`.trim(),
    `${location.postcode || ""} ${location.city || ""}`.trim(),
    location.country || "",
  ]
    .filter((i) => i.length !== 0)
    .join(", ");

export const legacySubmissionId = (submissionId: string): string => uuidv5(submissionId, MOREAPP_NAMESPACE);

export const getRememberedFieldId = (fieldId: string, formId: string): string =>
  uuidv5(`${fieldId}${formId}`, MOREAPP_NAMESPACE); // field 'uid' not unique (yet), it's identical for form-copies

export const removeWidgetVersionNumber = (widget: string): string => widget.substring(0, widget.lastIndexOf(":"));

export const parseJwt = (token: string): any => {
  if (!token) {
    throw new InvalidJWTError("JWT is empty");
  }
  if (!token.includes(".")) {
    throw new InvalidJWTError("Can't split URL from JWT");
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString());
  try {
    return JSON.parse(jsonPayload);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    throw new InvalidJWTError("Failed to parse JWT to JSON");
  }
};

export const toFormattedCurrency = (number: number, currency = "", precision = PRECISION_DEFAULT): string =>
  Intl.NumberFormat(navigator.language, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    style: currency ? "currency" : undefined,
    currency: currency || undefined,
  }).format(number);

export const toNumber = (localeString: string, precision = PRECISION_DEFAULT): number =>
  parseFloat(parseFloat(localeString.replace(",", ".")).toFixed(precision));

export const toLocaleString = (number: number): string => number.toString().replace(".", delimiter);

export const getSearchEntryHumanText = (data: Record<string, any>, entryFields: string[]): string =>
  entryFields
    .map((key) => [key, data[key]])
    .filter(([, value]) => !(value instanceof Object)) // Filter out all arrays and maps
    .map(([, value]) => value?.toString())
    .filter((value) => !isEmpty(value))
    .join(", ");

export class InvalidJWTError extends Error {}

export const optimizationImage = (url: string): string => `${IMAGE_OPT_URL}/?url=${encodeURIComponent(url)}`;

const REGEX_PLACEHOLDER = /([^${]*?)\w(?=})/g;
const REGEX_PLACEHOLDER_WITH_DOT = /([^${]*?)\w(?=\.)/g;
export const getPlaceholderDataNames = (value?: string): string[] => [
  ...Array.from(value?.matchAll(REGEX_PLACEHOLDER) ?? [], (m) => m[0]),
  ...Array.from(value?.matchAll(REGEX_PLACEHOLDER_WITH_DOT) ?? [], (m) => m[0]),
];

const stripNonDecimal = (input: string): string => {
  // Ensure only the last decimal separator is kept
  const decimalIndex = input.lastIndexOf(".");
  if (decimalIndex !== -1) {
    const firstPart = input.slice(0, decimalIndex).replace(/\./g, "");
    const secondPart = input.slice(decimalIndex + 1);
    return secondPart.length > 0 ? `${firstPart}.${secondPart}` : firstPart;
  }
  return input;
};

export const stripNonNumericWithSeparators = (
  input: string,
  decimalSeparator: string,
  thousandsSeparator: string,
): string => {
  let result = input;
  // Remove thousands separator
  result = result.replace(new RegExp(`\\${thousandsSeparator}`, "g"), "");
  // Replace decimal separator with a dot
  result = result.replace(new RegExp(`\\${decimalSeparator}`, "g"), ".");
  // Remove non-numeric characters (except minus signs and decimal separators)
  result = result.replace(/[^\d.-]/g, "");

  return stripNonDecimal(result);
};

export const stripNonNumeric = (input: string): string => {
  let result;
  // Replace commas with dots (if needed)
  result = input.replace(/,/g, ".");

  // Remove non-numeric characters (except minus signs and decimal separators)
  result = result.replace(/[^\d.-]/g, "");

  return stripNonDecimal(result);
};
