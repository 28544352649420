import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import type { TokenAuthentication } from "./useAuth";
import type { MoreAppError } from "../context/MoreAppContext";
import { API_URL } from "../constants";

type UseFormTokenDetailsResult = {
  formTokenDetails: TokenAuthentication | undefined;
  isLoadingFormTokenDetails: boolean;
};

const useFormTokenDetails = (formToken?: string): UseFormTokenDetailsResult => {
  const queryResult = useQuery<TokenAuthentication, MoreAppError>({
    queryKey: ["formTokenDetails", formToken],
    queryFn: async () => getFormToken(formToken),
    enabled: !!formToken,
    staleTime: 0,
    gcTime: 0, // This call shouldn't be cached, it can change every refresh
    refetchOnWindowFocus: false, // Only refetch on refresh
  });

  return {
    formTokenDetails: queryResult.data,
    isLoadingFormTokenDetails: queryResult.isLoading,
  };
};

export const getFormToken = async (formToken?: string): Promise<TokenAuthentication> => {
  const { data } = await axios.get<TokenAuthentication>(`${API_URL}/client/authentication/token/${formToken}`, {
    headers: { "X-More-Token": formToken },
  });
  return data;
};

export default useFormTokenDetails;
