import type { FC } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { VirtuosoHandle } from "react-virtuoso";
import { Virtuoso } from "react-virtuoso";
import useMiniSearch from "../../../hooks/useMiniSearch";
import type { DataSource, DataSourceEntry } from "../../../types/Datasource";
import CatalogueListItem from "./CatalogueListItem";
import type { Currency } from "../../../types/Currency";
import CatalogueItemContent from "./CatalogueItemContent";
import { asCurrency, getCatalogueItemTitle } from "../../../utils/datasourceUtil";
import DatasourceStatusBanner from "./DatasourceStatusBanner";
import useDrawer from "../../../hooks/useDrawer";
import { Spinner } from "../../../storybook/components/Spinner/Spinner";
import { Drawer } from "../../../storybook/components/Drawer/Drawer";

type SearchContentProps = {
  datasource: DataSource;
  query: string;
  entryFields: string[];
  subtitle?: string;
  showPrices: boolean;
  showVat: boolean;
  filterQueries?: string[];
  currency?: Currency;
  precision?: number;
  onAdd: (value: DataSourceEntry) => void;
  onInit: (value: boolean) => void;
  isExactQuery: boolean;
};

const CatalogueSearchContent: FC<SearchContentProps> = ({
  datasource,
  query,
  entryFields,
  subtitle,
  filterQueries,
  currency,
  precision,
  showPrices,
  showVat,
  isExactQuery,
  onAdd,
  onInit,
}) => {
  const virtuoso = useRef<VirtuosoHandle>(null);
  const options = useMemo(
    () => ({
      matchAllOnEmptyQuery: true,
      miniSearchOptions: {
        fields: entryFields,
        extractField: (datasourceEntry: DataSourceEntry, fieldName: string): string => datasourceEntry.data[fieldName],
        idField: "id",
      },
    }),
    [entryFields],
  );
  const getId = useCallback((entry: DataSourceEntry) => entry.data.id, []);
  const { result: hits, isInitializing } = useMiniSearch<DataSourceEntry>(
    query,
    true,
    options,
    getId,
    datasource.entries,
    filterQueries,
    isExactQuery,
  );

  const [activeOpen, setActiveOpen] = useDrawer("catalogue-active");
  const [activeItem, setActiveItem] = useState<Record<string, string>>();

  useEffect(() => virtuoso.current?.scrollToIndex(0), [hits]);

  useEffect(() => onInit(!isInitializing), [isInitializing]);

  if (isInitializing) {
    return <Spinner className="mx-auto mt-4" />;
  }

  return (
    <>
      <DatasourceStatusBanner dataSourceId={datasource.id} />
      <Virtuoso
        ref={virtuoso}
        data={hits}
        itemContent={(_index, hit) => (
          <CatalogueListItem
            key={hit.item.data.id}
            onClick={() => onAdd(hit.item)}
            onClickInfo={() => {
              setActiveItem(hit.item.data);
              setActiveOpen(true);
            }}
            name={hit.item.data.name}
            description={hit.item.data.description}
            thumbnail={hit.item.data.thumbnail ?? hit.item.data.photo}
            priceExVat={asCurrency(parseFloat(hit.item.data.priceExVat), currency, precision)}
            showPrices={showPrices}
          />
        )}
      />
      <Drawer
        open={activeOpen}
        header={{
          kind: "simple",
          title: getCatalogueItemTitle(activeItem || {}, entryFields),
          subtitle,
          button: {
            kind: "icon",
            icon: "XIcon",
            onClick: () => setActiveOpen(false),
          },
        }}
        onClose={() => setActiveOpen(false)}
        contentPadding={false}
      >
        <CatalogueItemContent item={activeItem} showPrice={showPrices} showVat={showVat} />
      </Drawer>
    </>
  );
};

export default CatalogueSearchContent;
