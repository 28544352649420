import type { AxiosInstance } from "axios";
import type { RxCollection, RxDatabase, RxDocument } from "rxdb";
import { JSONPath } from "jsonpath-plus";
import pTimeout from "p-timeout";
import { isEmpty, isNil } from "lodash-es";
import type { Submission, SubmissionForm } from "../types/Submission";
import uuidv4 from "./uuid";
import {
  getCalculatedFieldId,
  getDefaultValue,
  getFieldFromFormVersions,
  getFormVersionForEntry,
  getFormVersionForField,
  getInitialValue,
  getPinFormVersions,
  hasKnownWidget,
} from "./formUtil";
import type { Field, RememberedField, WidgetResult } from "../types/Field";
import { fieldToWidgetResult } from "../types/Field";
import type { FileResult } from "../types/Widget";
import type { Form } from "../types/Folder";
import type { AbstractForm, FormField, FormVersion, WidgetProperties } from "../types/FormVersion";
import type { DeviceInfo } from "./deviceUtil";
import { getConnection, getCurrentAppInfo } from "./deviceUtil";
import { getLocation } from "./locationUtil";
import logger from "./logger";
import type { DBCollections, FieldDocument } from "./databaseUtil";
import type { SubmissionFormData } from "../components/Form";
import type { FieldState, UniqueFieldId } from "../types/SubmissionState";
import { EPOCH_DATE_ISO, nowToISO } from "./dateUtil";
import { evaluateRules } from "./ruleEvaluationUtil";
import type { FieldResult } from "../types/Rules";
import { getRememberedFieldId, removeWidgetVersionNumber } from "./stringUtil";
import { findFormVersions } from "./FormEngine";
import { sleep } from "./sleepUtil";

const EXCLUDED_WIDGETS = ["signature"];
const LOCATION_RETRIEVAL_TIMEOUT = 15_000;

export const createNewSubmission = async (
  form: Form,
  customerId: number,
  submissions: RxCollection<Submission>,
): Promise<RxDocument<Submission, object>> => {
  const id = uuidv4();
  const submission = buildNewSubmission(id, customerId, form.id, form.publishedVersion.formVersion, { ...form.meta });
  return submissions.upsert(submission);
};

export const createNewFields = async (
  submissionId: string,
  formVersion: FormVersion,
  formId: string,
  deviceId: string,
  userId: string,
  fieldCollection: RxCollection<Field>,
  rememberedFieldCollection: RxCollection<RememberedField>,
): Promise<Field[]> => {
  const existingFields = await fieldCollection.find().where("submissionId").eq(submissionId).exec();
  const rememberedFields = await rememberedFieldCollection.find().where("formId").eq(formId).exec();

  const fields = await ensureFields(
    submissionId,
    formId,
    formVersion,
    deviceId,
    userId,
    existingFields,
    rememberedFields,
  );

  // See DEV-5931: decreases the chance that fields are pushed BEFORE the matching submission exists, resulting in foreign key violations
  await sleep(500);
  await fieldCollection.bulkUpsert(fields);
  return fields;
};

// TODO: add unit-tests!
export const ensureFields = async (
  submissionId: string,
  formId: string,
  formVersion: FormVersion,
  deviceId: string,
  userId: string | undefined,
  existingFields: Field[] = [],
  rememberedFields: RememberedField[] = [],
): Promise<Field[]> => {
  const formVersions = findFormVersions(formVersion);

  // new root fields
  const fields = buildNewFields(formVersion, submissionId, formId, deviceId, existingFields, rememberedFields, userId);

  // new nested fields for existing entries
  const existingFieldsWithEntries = existingFields.filter((field) => !isEmpty(field.entries));
  await Promise.allSettled(
    existingFieldsWithEntries.flatMap((field) =>
      field.entries
        .filter((x) => !x.deleted)
        .map(async (entry) => {
          const nestedFormVersion = getFormVersionForEntry(field, entry, formVersions, formVersion.fieldProperties);
          if (isNil(nestedFormVersion)) {
            const formField = getFieldFromFormVersions(formVersions, field.formFieldId);
            const isPin = removeWidgetVersionNumber(formField?.widget!) === "com.moreapps:pin";
            const isPinWithoutForm = isPin && isEmpty(getPinFormVersions(formField!, formVersion.fieldProperties));
            if (!isPinWithoutForm) {
              logger.error("Couldn't find FormVersion for existing entry", null, {
                extra: { widget: formField?.widget, entry: entry.id },
              });
            }
            return;
          }
          fields.push(
            ...buildNewFields(
              nestedFormVersion,
              submissionId,
              formId,
              deviceId,
              existingFields,
              rememberedFields,
              userId,
              entry.id,
              field.id,
            ),
          );
        }),
    ),
  );

  return fields;
};

const buildNewFields = (
  formVersion: AbstractForm,
  submissionId: string,
  formId: string,
  deviceId: string,
  existingFields: Field[],
  rememberedFields: RememberedField[],
  userId?: string,
  entryId?: string,
  parentId?: UniqueFieldId,
): Field[] =>
  formVersion.fields
    .filter(hasKnownWidget)
    .filter(({ uid }) => {
      const uniqueFieldId = getCalculatedFieldId(uid, submissionId, entryId, parentId);
      const existingField = existingFields.find((x) => x.id === uniqueFieldId);
      // `updatedBy` is set for fields pre-filled by tasks, or when mutated in the app.
      // The only way `updatedBy` is empty for an existing field, is when a Task is created.
      // Why? Because we create all fields in Hasura, even if they weren't pre-filled. Only the pre-filled ones have `updatedBy` set.
      return isNil(existingField) || isNil(existingField.updatedBy);
    })
    .map((formField, index) => {
      const uniqueFieldId = getCalculatedFieldId(formField.uid, submissionId, entryId, parentId);

      // Replace default value with remembered data, if available
      const rememberedField = formField.properties.remember_input
        ? rememberedFields.find((x) => x.id === getRememberedFieldId(formField.uid, formId))
        : undefined;

      const widgetResult = getInitialValue(uniqueFieldId, formField, submissionId, rememberedField, entryId, parentId);
      return {
        id: uniqueFieldId,
        submissionId,
        updatedAt: EPOCH_DATE_ISO,
        updatedBy: userId,
        formFieldId: widgetResult.meta.formFieldId,
        dataName: widgetResult.meta.dataName,
        widget: widgetResult.meta.widget,
        type: widgetResult.type,
        data: widgetResult.rawValue,
        parentId,
        entryId,
        deviceId,
        hidden: false,
        compressed: widgetResult.meta.compressed,
        entries: [],
        status: "draft",
        evaluatedRules: [],
        order: index,
        error: undefined,
        _deleted: false,
      };
    });

export const buildNewSubmission = (
  id: string,
  customerId: number,
  formId: string,
  formVersionId: string,
  form: SubmissionForm,
): Submission => ({
  id,
  customerId,
  formId,
  formVersionId,
  status: "draft",
  form: {
    name: form.name,
    description: form.description,
    icon: form.icon,
    iconColor: form.iconColor,
  },
  meta: {},
  createdAt: nowToISO(),
  updatedAt: nowToISO(),
  sendType: "remote_trigger",
  type: "draft",
});

export const extractAbstractForms = (formVersion: FormVersion): AbstractForm[] =>
  (
    JSONPath({
      path: "$..fields^",
      json: formVersion,
    }) as AbstractForm[]
  )
    // The JSONPath above also selects the `settings.searchSettings` object since it also contains a `fields` property,
    // this is not what we want, so we filter out everything that doesn't have the main properties of an abstract form.
    // In the future it might be a good idea to refactor the initial selection with JSONPath completely.
    .filter((x) => x.fields && x.rules && x.settings);

export const copySubmission = async (
  submission: Submission,
  fields: Field[],
  formVersion: FormVersion,
  client: AxiosInstance,
  username: string,
  deviceId: string,
): Promise<{ submission: Submission; fields: Field[] }> => {
  const newSubmission: Submission = {
    id: uuidv4(),
    customerId: submission.customerId,
    formId: submission.formId,
    formVersionId: formVersion.id,
    description: submission.description,
    status: "draft",
    form: submission.form,
    meta: {},
    createdAt: nowToISO(),
    updatedAt: nowToISO(),
    sendType: "remote_trigger",
    type: "draft",
  };
  const abstractForms = extractAbstractForms(formVersion);

  // Calculate new Entry IDs, which have to be unique across the database
  const newEntryIds = fields.reduce(
    (acc, field) => {
      field.entries.forEach((entry) => (acc[entry.id] = uuidv4()));
      return acc;
    },
    {} as Record<string, string>,
  );

  const copiedFileFields = await Promise.all(
    fields
      .filter((field) => !EXCLUDED_WIDGETS.includes(field.widget)) // Filter sensitive fields
      .filter((field) => field.type === "file")
      .map(async (field): Promise<Field> => {
        let fileData: FileResult | undefined;
        if (field.type === "file" && (field.data as FileResult)?.remoteId) {
          const file = field.data as FileResult;
          const { data: remoteId } = await client.post<string>(
            `/api/v1.0/customers/${submission.customerId}/registrationFile/${file.remoteId}/copy`,
            null,
            { params: { hasuraSubmissionUuid: newSubmission.id } },
          );
          fileData = { ...file, id: uuidv4(), remoteId };
        }
        return {
          ...field,
          uploadStatus: "uploaded",
          data: fileData ?? field.data,
        };
      }),
  );

  const copiedFields = fields
    .filter((field) => !EXCLUDED_WIDGETS.includes(field.widget)) // Filter sensitive fields
    .map((field) =>
      cloneField(field, fields, newEntryIds, newSubmission, copiedFileFields, abstractForms, username, deviceId),
    );
  const copiedFieldsWithRules = getSortedFields(
    fieldsWithRules(copiedFields, newSubmission.id, formVersion, abstractForms, username),
  ); // Ensure Set Value rules aren't triggered on insert
  return { submission: newSubmission, fields: copiedFieldsWithRules };
};

/**
 * Submission API doesn't know/care about the values of hidden fields.
 * This function adds the default value into the Field when it was hidden, because we need to use it for the copied submission.
 *
 */
const getFieldDataWithHiddenRules = (
  field: Field,
  formVersions: AbstractForm[],
  submissionId: string,
  ruleAction?: FieldResult,
): unknown =>
  ruleAction?.hidden && isNil(field.data) ? getDefaultRawValueForField(field, formVersions, submissionId) : field.data;

const fieldsWithRules = (
  fields: Field[],
  submissionId: string,
  formVersion: FormVersion,
  formVersions: AbstractForm[],
  username: string,
): Field[] => {
  const ruleActions = evaluateRules(submissionId, fields, formVersion, username);
  return fields.map((field) => {
    const ruleAction = ruleActions.find((x) => x.uniqueFieldId === field.id);
    const data = getFieldDataWithHiddenRules(field, formVersions, submissionId, ruleAction);
    return !ruleAction
      ? field
      : { ...field, data, hidden: !!ruleAction.hidden, evaluatedRules: ruleAction.ruleResults };
  });
};

const getParentId = (
  field: Field,
  submission: Submission,
  newEntryIds: Record<string, string>,
  fields: Field[],
): UniqueFieldId | undefined => {
  const parent = fields.find((parentField) => field.parentId === parentField.id);
  if (!parent) {
    return undefined;
  }

  const entryId = parent.entryId ? newEntryIds[parent.entryId] : undefined;
  const parentId = getParentId(parent, submission, newEntryIds, fields);
  return parent ? getCalculatedFieldId(parent.formFieldId, submission.id, entryId, parentId) : undefined;
};

const cloneField = (
  field: Field,
  fields: Field[],
  newEntryIds: Record<string, string>,
  newSubmission: Submission,
  copiedFileFields: Field[],
  formVersions: AbstractForm[],
  username: string,
  deviceId: string,
): Field => {
  const entryId = field.entryId ? newEntryIds[field.entryId] : undefined;
  const parentId = getParentId(field, newSubmission, newEntryIds, fields);
  const fieldId = getCalculatedFieldId(field.formFieldId, newSubmission.id, entryId, parentId);
  const copiedData = copiedFileFields.find((copiedField) => copiedField.id === field.id);

  return {
    ...field,
    id: fieldId,
    data: copiedData?.data ?? field.data,
    submissionId: newSubmission.id,
    parentId,
    entryId,
    deviceId,
    entries: field.entries
      .filter((entry) => !entry.deleted)
      .map((entry) => ({
        ...entry,
        id: newEntryIds[entry.id],
        submissionId: newSubmission.id,
      })),
    order: getOrderInFormVersion(formVersions, field.formFieldId),
    updatedBy: username,
    evaluatedRules: [], // This will be calculated later when initializing the submission state (FormEngine.run)
    status: "draft",
    _deleted: false,
  };
};

export const getNestedEntries = (
  fields: FieldState<WidgetProperties, WidgetResult<unknown>>[],
  entryId: string,
  result: string[] = [],
): string[] => {
  const entryIds = fields
    .filter((field) => field.value.meta.entryId === entryId)
    .flatMap((field) => (field.value.entries || []).map((entry) => entry.id));

  if (entryIds.length === 0) {
    return [...result, entryId];
  }

  const nestedEntries = entryIds.flatMap((nestedEntryId) => getNestedEntries(fields, nestedEntryId));
  return [...result, entryId, ...nestedEntries];
};

export const getSortedFields = (fields: Field[]): Field[] => {
  const sortedSubforms = fields
    .filter((field) => field.entries?.length > 0)
    .map((field) => ({ field, depth: getSubformDepth(0, field, fields) }))
    .sort((a, b) => a.depth - b.depth)
    .map((pair) => pair.field);
  const otherFields = fields.filter((f) => f.entries?.length === 0);
  return sortedSubforms.concat(otherFields);
};

export const fieldsToSubmissionFormData = (fields: Field[]): SubmissionFormData =>
  fields.reduce((acc, field) => {
    acc[field.id] = fieldToWidgetResult(field);
    return acc;
  }, {} as SubmissionFormData);

export const updateSubmissionMeta = async (
  submission: RxDocument<Submission>,
  device: DeviceInfo,
): Promise<RxDocument<Submission, object>> => {
  let location;
  try {
    location = await pTimeout(getLocation(), { milliseconds: LOCATION_RETRIEVAL_TIMEOUT });
  } catch (e) {
    logger.log("Couldn't retrieve location", e);
  }
  return submission.incrementalPatch({
    meta: { device, app: await getCurrentAppInfo(), connection: await getConnection(), location },
  });
};

const getOrderInFormVersion = (formVersions: AbstractForm[], formFieldId: string): number =>
  getFormVersionForField(formVersions, formFieldId)?.fields?.findIndex((x) => x.uid === formFieldId) ?? -1;

const getSubformDepth = (depth: number, field: Field, fields: Field[]): number => {
  if (!field.parentId) {
    return depth;
  }
  const parent = fields.find((f) => f.id === field.parentId);
  return parent ? getSubformDepth(depth + 1, parent, fields) : depth;
};

export const getSubmissionFormData = async (fields: FieldDocument[]): Promise<SubmissionFormData> => {
  const plainFields = fields.map((doc) => doc.toMutableJSON()); // prevent loading RxDB Proxy-objects
  return fieldsToSubmissionFormData(plainFields);
};

export const getStaticDefaultValues = (formVersion: FormVersion, submissionId: string): SubmissionFormData => {
  const fields: Field[] = formVersion.fields.map((field, index) => getStaticDefaultValue(field, index, submissionId));
  return fieldsToSubmissionFormData(fields);
};

export const getDefaultRawValueForField = (
  field: Field,
  formVersions: AbstractForm[],
  submissionId: string,
): unknown => {
  const formVersion = getFormVersionForField(formVersions, field.formFieldId);
  const formField = formVersion?.fields.find((x) => x.uid === field.formFieldId);
  if (!formField) {
    return undefined;
  }
  const defaultValue = getDefaultValue(field.id, formField, submissionId, field.entryId, field.parentId);
  return defaultValue.rawValue;
};

/**
 * Get default value to use in a static form context. I.e. templates or search form.
 * This doesn't start with validation result.
 * NOTE: This only gets root-form default values, as there's no use-case for default values in subform-entries
 */
const getStaticDefaultValue = (formField: FormField<any>, order: number, submissionId: string): Field => {
  const uniqueFieldId = getCalculatedFieldId(formField.uid, submissionId);
  const initialValue = getInitialValue(uniqueFieldId, formField, submissionId, undefined);
  return {
    id: uniqueFieldId,
    submissionId,
    updatedAt: EPOCH_DATE_ISO,
    formFieldId: initialValue.meta.formFieldId,
    dataName: initialValue.meta.dataName,
    widget: initialValue.meta.widget,
    type: initialValue.type,
    data: initialValue.rawValue,
    hidden: false,
    compressed: false,
    entryId: initialValue.meta.entryId,
    parentId: initialValue.meta.parentId,
    entries: [],
    status: "draft",
    evaluatedRules: [],
    order,
    _deleted: false,
  };
};

export const getActiveSubmissionIds = async (
  submissionIds: string[],
  db: RxDatabase<DBCollections>,
): Promise<string[]> => {
  const result = await db.submissions.find().where("id").in(submissionIds).exec();
  return result.filter((submission) => !submission.deleted).map((submission) => submission.id);
};
