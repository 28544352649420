import useSubmissionStore from "./useSubmissionStore";
import type { UniqueFieldId } from "../types/SubmissionState";
import type { SubForm } from "../types/FormVersion";

const useActionAddEntry = (): ((
  uniqueFieldId: UniqueFieldId,
  formVersion?: SubForm,
  meta?: Record<string, unknown>,
) => string) => useSubmissionStore((store) => store.addEntry);

export default useActionAddEntry;
